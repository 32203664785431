<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="用户名">
        <a-input
            v-decorator="['username', { rules: [{ required: true, message: '请输入用户名' }], initialValue: item && item.username }]"
        />
      </a-form-item>
      <a-form-item label="真实姓名">
        <a-input
            v-decorator="['realName', { rules: [{ required: true, message: '请输入真实姓名' }], initialValue: item && item.realName }]"
        />
      </a-form-item>
      <a-form-item label="性别">
        <a-radio-group
            v-decorator="['gender', { rules: [{ required: true, message: '请选择性别' }], initialValue: item && item.gender || false }]">
          <a-radio-button :value="1">
            男
          </a-radio-button>
          <a-radio-button :value="2">
            女
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
            v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号' }], initialValue: item && item.phone }]"
        />
      </a-form-item>
      <a-form-item label="邮箱">
        <a-input
            v-decorator="['mail', { rules: [{ required: true, message: '请输入邮箱' }], initialValue: item && item.mail }]"
        />
      </a-form-item>
      <a-form-item label="用户头像">
        <a-input
            v-decorator="['portrait', { rules: [{ required: true, message: '请输入用户头像' }], initialValue: item && item.portrait }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {updateRoute, updateUser} from "@/api/route";

export default {
  name: "userEdit",
  data() {
    return {
      title: this.item ? '修改用户' : '新增用户',
      visible: false,
      form: this.$form.createForm(this),
      item: {}
    }
  },
  methods: {
    toggleVisible(item) {
      this.item = item
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updateUser({
            ...this.item,
            ...values,
          })
          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改用户' : '新增用户'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
