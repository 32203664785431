<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      okText="授予"
      title="模块列表"
      width="60%"
      @cancel="handleCancel" @ok="handleOk"
  >
    <a-form layout="inline">
      <a-form-item label="模块名称">
        <a-input v-model="query.moduleName" placeholder="请输入模块名称"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             :row-selection="rowSelection" style="margin-top: 24px;"
             @change="handleTableChange">
    </a-table>
  </a-modal>
</template>

<script>
import {deleteModule, queryModuleLevelList, queryModuleList} from "@/api/module";
import _ from "lodash";

export default {
  name: "userModuleSelect",
  data() {
    return {
      query: {
        moduleName: undefined
      },
      columns: [
        {
          title: '模块编号',
          dataIndex: 'moduleCode',
          key: 'moduleCode',
        },
        {
          title: '模块名称',
          dataIndex: 'moduleName',
          key: 'moduleName',
        },
        {
          title: '模块类型',
          dataIndex: 'moduleType',
          key: 'moduleType',
        },
        {
          title: '跳转路径',
          dataIndex: 'baseUrl',
          key: 'baseUrl',
        },
        {
          title: '等级',
          dataIndex: 'levelCode',
          key: 'levelCode',
        },
        {
          title: '父级链',
          dataIndex: 'permissions',
          key: 'permissions',
        },
        {
          title: '模块状态',
          dataIndex: 'moduleStatusStr',
          key: 'moduleStatusStr',
        },
        {
          title: '扩展编码',
          dataIndex: 'extendsCode',
          key: 'extendsCode',
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      moduleLevelList: [],
      visible: false,
      selectedCode: [],
    }
  },
  mounted() {
    this.queryModuleLevelList()
  },
  methods: {
    async queryModuleList() {
      this.loading = true

      let data = await queryModuleList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryModuleList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryModuleList()
    },
    async deleteModule(module) {
      await deleteModule([module.id])

      this.$message.success('删除成功')
      await this.queryModuleList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (module, index) => {
        return {
          key: module.moduleCode,
          ...module,
          levelCode: _.find(this.moduleLevelList, {level: module.level}).levelCode
        }
      })
    },
    async queryModuleLevelList() {
      this.moduleLevelList = (await queryModuleLevelList()).result
    },
    toggleVisible() {
      this.visible = !this.visible
      this.queryModuleList()
    },
    handleOk(e) {
      this.$emit('success', this.selectedCode)
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedCode = _.map(selectedRows, row => {
            return row.moduleCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
