<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      title="模块列表"
      width="60%"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-space>
      <a-button type="primary" @click="showUserModuleSelect">
        授予模块
      </a-button>
      <a-popconfirm
          cancel-text="取消"
          ok-text="取消模块"
          title="确认取消模块？"
          @confirm="cancelUserModule"
      >
        <a-button type="danger">
          取消模块
        </a-button>
      </a-popconfirm>
    </a-space>

    <a-form layout="inline">

    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" 
              :row-selection="rowSelection" @change="handleTableChange">

    </a-table>

    <user-module-select ref="userModuleSelect" @success="grantUserModule"/>
  </a-modal>
</template>

<script>
import {deleteUserCache, grantUserModule, queryUserModule} from "@/api/route";
import _ from "lodash";
import userModuleSelect from "./userModuleSelect";
import {queryModuleLevelList} from "@/api/module";

export default {
  name: "userModuleList",
  components: {userModuleSelect},
  data() {
    return {
      userCode: undefined,
      visible: false,
      columns: [
        {
          title: '模块编号',
          dataIndex: 'moduleCode',
          key: 'moduleCode',
        },
        {
          title: '模块名称',
          dataIndex: 'moduleName',
          key: 'moduleName',
        },
        {
          title: '模块类型',
          dataIndex: 'moduleType',
          key: 'moduleType',
        },
        {
          title: '等级',
          dataIndex: 'levelCode',
          key: 'levelCode',
        },
        {
          title: '父级链',
          dataIndex: 'permissions',
          key: 'permissions',
        },
        {
          title: '扩展编码',
          dataIndex: 'extendsCode',
          key: 'extendsCode',
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      selectedCode: [],
      moduleLevelList: [],
    }
  },
  mounted() {
    this.queryModuleLevelList()
  },
  methods: {
    toggleVisible(userCode) {
      this.userCode = userCode
      this.visible = !this.visible

      this.queryUserModuleList()
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryUserModuleList()
    },
    async queryUserModuleList() {
      this.loading = true

      let data = await queryUserModule({
        userCode: this.userCode,
        limit: 10000
      })

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    convertQuery() {
      return {
        ...this.query,

        limit: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (module, index) => {
        return {
          key: module.moduleCode,
          ...module,
          levelCode: _.find(this.moduleLevelList, {level: module.level}).levelCode
        }
      })
    },
    async cancelUserModule() {
      await grantUserModule({
        userCode: this.userCode,
        isGrant: false,
        moduleCodes: this.selectedCode,
      })
      await this.queryUserModuleList()
      await deleteUserCache()
    },
    async grantUserModule(moduleCodes) {
      await grantUserModule({
        userCode: this.userCode,
        isGrant: true,
        moduleCodes: moduleCodes,
      })
      await this.queryUserModuleList()
      await deleteUserCache()
      this.$emit('success')
    },
    showUserModuleSelect() {
      this.$refs.userModuleSelect.toggleVisible()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryUserModuleList()
    },
    async queryModuleLevelList() {
      this.moduleLevelList = (await queryModuleLevelList()).result
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedCode = _.map(selectedRows, row => {
            return row.moduleCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
