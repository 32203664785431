<template>
  <div>
    <a-form layout="inline">
      <a-form-item
          label="用户编号"
      >
        <a-input v-model="query.userCode" placeholder="请输入用户编号" @change="customQuery"/>
      </a-form-item>
      <a-form-item
          label="用户名"
      >
        <a-input v-model="query.username" placeholder="请输入用户名" @change="customQuery"/>
      </a-form-item>
      <a-form-item
          label="真实姓名"
      >
        <a-input v-model="query.realName" placeholder="请输入真实姓名" @change="customQuery"/>
      </a-form-item>
      <a-form-item
          label="手机号"
      >
        <a-input v-model="query.phone" placeholder="请输入手机号" @change="customQuery"/>
      </a-form-item>
      <a-form-item
          label="邮箱"
      >
        <a-input v-model="query.mail" placeholder="请输入邮箱" @change="customQuery"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editUser(undefined)">增加</a-button>
      </a-form-item>
    </a-form>

    <a-table size="small" bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 24px;"
              :scroll="{ y: 600}"
             @change="handleTableChange">
      <span slot="roleNames" slot-scope="text">
        <a-tag v-for="role in text" :key="role" color="pink">
        {{ role }}
        </a-tag>
      </span>
      <template slot-scope="text, record" slot="portrait" >
          <img :src="record.portrait" class="portrait-logo"/>
      </template>
      <span slot="disable" slot-scope="text">
        <a-tag v-if="text" color="#f50">禁用中</a-tag>
        <a-tag v-else color="#108ee9">启用中</a-tag>
      </span>

      <span slot="permissionAction" slot-scope="text, record">
        <a @click="showUserRoleList(record)">角色列表</a>
        <a-divider type="vertical"/>
        <a @click="showUserModuleList(record)">模块列表</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            v-if="record.userStatus === 500"
            cancel-text="取消"
            ok-text="启用"
            title="确认启用？"
            @confirm="disableUser(record, false)">
          <a>启用</a>
        </a-popconfirm>
        <a-popconfirm
            v-else
            cancel-text="取消"
            ok-text="禁用"
            title="确认禁用？"
            @confirm="disableUser(record, true)">
          <a>禁用</a>
        </a-popconfirm>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="重置密码"
            title="确认重置密码？"
            @confirm="resetUserPassword(record)">
          <a>重置密码</a>
        </a-popconfirm>
        <a-divider type="vertical"/>
       <a @click="showUpdatePwdModal(record)">修改密码</a>
      </span>
     
      <span slot="action" slot-scope="text, record">
        <a @click="editUser(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确认删除？"
            @confirm="deleteUser(record)"
        >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <user-role-list ref="userRoleList" @success="queryUserList"/>
    <user-module-list ref="userModuleList" @success="queryUserList"/>
    <user-edit ref="edit" @success="queryUserList"/>
    <a-modal title="修改密码" @ok="handleOk" v-model="updatepwdVisible">
      <p>
        修改密码：<a-input type="input" size="large" v-model="newPassword.password"></a-input>
      </p>
    </a-modal>
  </div>
</template>

<script>
import { updateUserPasswordApi} from '@/api/user'
import {deleteRoute, deleteUser, disableUser, queryRouteList, queryUserList, resetUserPassword} from "@/api/route";
import _ from "lodash";
import userRoleList from "./component/userRoleList";
import userModuleList from "./component/userModuleList";
import userEdit from "./component/userEdit";


export default {
  name: "userList",
  components: {userRoleList, userEdit, userModuleList},
  data() {
    return {
      newPassword: {userCode: '', password: ''},
      updatepwdVisible: false,
      query: {
        userCode: undefined,
        username: undefined,
        realName: undefined,
        mail: undefined,
        phone: undefined,
      },
      columns: [
        {
          title: '用户头像',
          dataIndex: 'portrait',
          key: 'portrait',
          scopedSlots: {customRender: 'portrait'},
        },
        {
          title: '用户编号',
          dataIndex: 'userCode',
          key: 'userCode',
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '真实姓名',
          dataIndex: 'realName',
          key: 'realName',
          align: 'center'
        },
        {
          title: '邮箱',
          dataIndex: 'mail',
          key: 'mail',
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'genderStr',
          key: 'genderStr',
          width: 80,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key: 'phone',
          align: 'center'
        },
        {
          title: '角色',
          dataIndex: 'roleNames',
          key: 'roleNames',
          scopedSlots: {customRender: 'roleNames'},
        },
        {
          title: '状态',
          dataIndex: 'userStatusStr',
          key: 'userStatusStr',
          scopedSlots: {customRender: 'userStatusStr'},
        },
        {
          title: '权限操作',
          key: 'permissionAction',
          fixed: 'right',
          scopedSlots: {customRender: 'permissionAction'},
        },
        {
          title: '',
          key: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
    }
  },
  mounted() {
    this.queryUserList()
  },
  methods: {
    showUpdatePwdModal(record) {
      this.updatepwdVisible = true
      this.newPassword.userCode = record.userCode
    },
    handleOk() {
      updateUserPasswordApi({...this.newPassword}).then(res => {
        this.$message.success('修改密码成功')
        this.updatepwdVisible = false
      }).catch(() => {
        this.$message.error('修改密码失败')
      })
    },
    async queryUserList() {
      this.loading = true

      let data = await queryUserList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryUserList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryUserList()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (user, index) => {
        return {
          key: user.userCode,
          ...user,
        }
      })
    },
    showUserRoleList(record) {
      this.$refs.userRoleList.toggleVisible(record.userCode)
    },
    showUserModuleList(record) {
      this.$refs.userModuleList.toggleVisible(record.userCode)
    },
    editUser(user) {
      this.$refs.edit.toggleVisible(user)
    },
    async deleteUser(user) {
      await deleteUser([user.id])
      this.$message.success('成功')
      await this.queryUserList()
    },
    async disableUser(record, disable) {
      await disableUser({
        userCode: record.userCode,
        disable,
      })
      this.$message.success('成功')
      await this.queryUserList()
    },
    async resetUserPassword(record) {
      await resetUserPassword({
        userCode: record.userCode,
      })
      this.$message.success('成功')
      await this.queryUserList()
    }
  },
}
</script>

<style scoped>
.portrait-logo{
  width: 40px;
  height: 40px;
  margin: auto;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
