<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      title="角色列表"
      width="60%"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-space>
      <a-button type="primary" @click="showUserRoleSelect">
        授予角色
      </a-button>
      <a-popconfirm
          cancel-text="取消"
          ok-text="取消角色"
          title="确认取消角色？"
          @confirm="cancelUserRole"
      >
        <a-button type="danger">
          取消角色
        </a-button>
      </a-popconfirm>
    </a-space>

    <a-form layout="inline">

    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">

    </a-table>

    <user-role-select ref="userRoleSelect" @success="grantUserRole"/>
  </a-modal>
</template>

<script>
import {
  deleteUserCache,
  grantRolePermission,
  grantUserRole,
  queryRolePermission,
  queryUserList,
  queryUserRole
} from "@/api/route";
import _ from "lodash";
import userRoleSelect from "./userRoleSelect";

export default {
  name: "userRoleList",
  components: {userRoleSelect},
  data() {
    return {
      userCode: undefined,
      visible: false,
      columns: [
        {
          title: '角色编码',
          dataIndex: 'roleCode',
          key: 'roleCode',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
        },
        {
          title: '描述',
          dataIndex: 'represent',
          key: 'represent',
        }
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      selectedCode: []
    }
  },
  methods: {
    toggleVisible(userCode) {
      this.userCode = userCode
      this.visible = !this.visible

      this.queryUserRoleList()
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryUserRoleList()
    },
    async queryUserRoleList() {
      this.loading = true

      let data = await queryUserRole({
        userCode: this.userCode
      })

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (role, index) => {
        return {
          key: role.roleCode,
          ...role,
        }
      })
    },
    async cancelUserRole() {
      await grantUserRole({
        userCode: this.userCode,
        isGrant: false,
        roleCodes: this.selectedCode,
      })
      await this.queryUserRoleList()
      await deleteUserCache()
    },
    async grantUserRole(roleCode) {
      await grantUserRole({
        userCode: this.userCode,
        isGrant: true,
        roleCodes: roleCode,
      })
      await this.queryUserRoleList()
      await deleteUserCache()
      this.$emit('success')
    },
    showUserRoleSelect() {
      this.$refs.userRoleSelect.toggleVisible()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryUserRoleList()
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedCode = _.map(selectedRows, row => {
            return row.roleCode
          })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: false,
            name: record.name,
          },
        }),
      }
    },
  }
}
</script>

<style scoped>

</style>
